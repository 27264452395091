import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Logo from '../../images/Logo_White.svg';
import { Auth } from '../../modules/auth_module';

const AppPageContainer = ({activePage, children}) => {
    const auth = Auth.use();
    useEffect(() => {
        console.log(auth)
        if (!auth.loggedIn) {
            navigate('/app/login');
        }
    }, [])
    return (
        <div className="w-full">
            <title>Kodama - Dashboard</title>
            <div className="lg:h-screen grid grid-cols-1 lg:grid-cols-12 lg:gap-4 lg:pr-4">
                <div className="lg:col-span-3 xl:col-span-2 pt-7 bg-primary text-gray-50">
                    <img className="h-8 mb-8 px-3" src={Logo} alt="Kodama" />
                    <Link to="/app/dashboard" className={'mx-2 my-1 rounded flex items-center p-2 px-3 hover:bg-secondary-dark hover:cursor-pointer '.concat(activePage === 'dashboard' ? 'bg-secondary-dark underline' : '')}>
                        <svg className="h-6 pr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                        <div>
                            Dashboard
                        </div>
                    </Link>
                    <Link to="/app/calculate" className={'mx-2 my-1 rounded flex items-center p-2 px-3 hover:bg-secondary-dark hover:cursor-pointer '.concat(activePage === 'calculate' ? 'bg-secondary-dark underline' : '')}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 pr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                        </svg>
                        <div>
                            Ingest
                        </div>
                    </Link>
                    <Link to="/app/emissions" className={'mx-2 my-1 rounded flex items-center p-2 px-3 hover:bg-secondary-dark hover:cursor-pointer '.concat(activePage === 'emissions' ? 'bg-secondary-dark underline' : '')}>
                        <svg className="h-6 pr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                        </svg>
                        <div>
                            Emissions
                        </div>
                    </Link>
                    <Link to="/app/offsets" className={'mx-2 my-1 rounded flex items-center p-2 px-3 hover:bg-secondary-dark hover:cursor-pointer '.concat(activePage === 'offsets' ? 'bg-secondary-dark underline' : '')}>
                        <svg className="h-6 pr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div>
                            Offsets
                        </div>
                    </Link>
                    <Link to="/app/api_keys" className={'mx-2 my-1 rounded flex items-center hover:bg-secondary-dark p-2 px-3 '.concat(activePage === 'api_keys' ? 'bg-secondary-dark underline' : '')}>
                        <svg className="h-6 pr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                        </svg>
                        <div>
                            API Keys
                        </div>
                    </Link>
                    <Link to="/app/organizations" className={'mx-2 my-1 rounded flex items-center p-2 px-3 hover:bg-secondary-dark hover:cursor-pointer '.concat(activePage === 'organization' ? 'bg-secondary-dark underline' : '')}>
                        <svg className="h-6 pr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                        <div>
                            Organization
                        </div>
                    </Link>
                    <Link to="/app/settings" className={'mx-2 my-1 rounded flex items-center hover:bg-secondary-dark p-2 px-3 '.concat(activePage === 'settings' ? 'bg-secondary-dark underline' : '')}>
                        <svg className="h-6 pr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <div>
                            Settings
                        </div>
                    </Link>
                </div>
                <div className="col-span-9 xl:col-span-10">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AppPageContainer;
 