import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { HashLoader } from 'react-spinners'
import { theme } from '../../../tailwind.config';
import AppPageContainer from '../../components/AppPageContainer';
import Logo from '../../images/Logo_Primary.svg';
import * as queryString from "query-string";
import { Auth, loginUser } from '../../modules/auth_module';

const LoginPage = ({location}) => {

    const toaster = queryString.parse(location.search).toast;
    const user = Auth.use();
    

    const [form, setForm] = useState({email: '', password: ''});
    const login = () => {
        loginUser({ email: form.email, password: form.password })
    }

    useEffect(() => {
        if (user.loggedIn) {
            navigate('/app/dashboard');
        }
        if (user.error) {

        }
    }, [user])

    const [toast, setToast] = useState();
    useEffect(() => {
        console.log(location, toaster);
        // if (toaster === 'confirm') {
        setToast(toaster);
        // }
    }, [toaster])
    
    return (
        // <AppPageContainer>
            <div className="grid grid-cols-1 w-full  lg:grid-cols-3">
                <title>Kodama - Login</title>
                <div className="relative lg:col-start-2 lg:col-end-2 mt-8 mb-5 flex justify-center max-h-12">
                    <img src={Logo} alt="Kodama"/>
                </div>
                { toast == 'confirm' && (
                    <div className="relative lg:col-start-2 lg:col-end-2 mt-8 mb-5 text-center justify-center p-2 bg-green-50 border border-primary rounded-md">
                        <p className="text-lg font-bold">Successfully confirmed your account</p>
                        <p>You can now login</p>
                    </div>
                )}
                { toast == 'registration' && (
                    <div className="relative lg:col-start-2 lg:col-end-2 mt-8 mb-5 text-center justify-center p-2 bg-green-50 border border-primary rounded-md">
                        <p className="text-lg font-bold">You've registered!</p>
                        <p>Please confirm your email address to continue with Kodama</p>
                    </div>
                )}
                { toast !== 'registration' && (
                    
                    
                <div className="lg:col-start-2 lg:col-end-2 shadow-lg rounded-xl p-5 border min-h-1/2 border-gray-200">
                    {user.state?.status === 'LOADING' && (
                        <div className="h-72 flex items-center justify-center">
                            <HashLoader color={theme.extend.colors.primary.DEFAULT}/>
                        </div>
                    )}
                    {user.state?.status !== 'LOADING' && (
                        <div className="h-72 flex flex-col">
                            <div>
                                <h2 className="text-xl font-bold">Login</h2>
                                <p className="text-sm">Welcome to Kodama</p>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="email" className="text-sm">Email</label>
                                <input 
                                    name="email" 
                                    value={form.email}
                                    onChange={(e) => setForm({...form, email: e.target.value})}
                                    className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                                />
                            </div>
                            <div className="mt-1">
                                <label htmlFor="password" className="text-sm">Password</label>
                                <input
                                    value={form.password}
                                    onChange={(e) => setForm({...form, password: e.target.value})}
                                    name="password"
                                    type="password"
                                    className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                                />
                            </div>
                            <div className="flex-1"/>
                            <div className="mt-3 flex w-full justify-end">
                                <div className="w-full">
                                    <button
                                        onClick={login}
                                        className="py-2 px-5 w-full rounded bg-primary text-white"
                                    >
                                        Login
                                    </button>
                                </div>
                            </div>
                            <div className="text-sm mt-5">
                                Don't have an account? <Link to="/app/register" className="text-secondary underline cursor-pointer">Register now.</Link>
                            </div>
                        </div>
                    )}
                </div>
                )}
            </div>
        // </AppPageContainer>
    )
};

export default LoginPage;
